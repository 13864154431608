import React from 'react';
import './Modal.scss';
import Panel from 'components/Panel/Panel';

export function Modal({
  header,
  footer,
  actions = [],
  ...props
}) {
  return (
    <section className="c-Modal">
      <Panel
        floated
        header={Boolean(props.title || props.closeAction)}
        footer={actions.length > 0}
        {...props}
      >
        {props.children}
      </Panel>
    </section>
  );
}

Modal.propTypes = {};

export default Modal;
