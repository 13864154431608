// @ts-check
/* eslint-disable eqeqeq */

import shortid from 'shortid';
import { hsluvToHex, hsluvToLch, hpluvToHex, hpluvToLch } from "hsluv";

/**
 * Format a number to a given number of decimal places
 *
 * @param {number} value - The number value that needs to be formatted
 * @param {number} precision - Number of decimal places in the output
 * @param {boolean} trimZeros - Should trailing zeros be removed
 * @return {string}
 */
export function formatNumber(value, precision = 2, trimZeros = false) {
  let result = value.toFixed(precision);
  if (trimZeros) result = result.replace(/(?:\.0+$|(\..+?)0+)$/, '$1');
  return result;
}

/**
 * Capitalize a string
 *
 * @param {string} s - The string to capitalize
 * @return {string}
 */
export function capitalize(s) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

/**
 * Checks if value is a number
 *
 * @param {any} v
 * @return {boolean}
 */
export function isNumber(v) {
  return typeof v == 'number';
}

/**
 * Return the first number element from the passed array
 *
 * @param {Array} values – The array of values to find the first number in
 * @param {any} fallback - The default value
 */
export function firstNumber([...values], fallback = null) {
  const value = values.find(value => typeof value == 'number');
  return value == undefined ? fallback : value;
}

/**
 * Associates an array of values with an array of keys
 *
 * @param {Array} values
 * @param {Array} keys
 * @return {Object}
 */
export function objectFromArray(values = [], keys = []) {
  return [...keys].reduce(
    (result, key, i) => (
      { ...result, [key]: values[i] }
    ), {}
  );
}

export function minDiff(min, max, edge) {
  if (edge <= 0) return 0;
  const d1 = max - min;
  const d2 = (edge - Math.abs(d1)) * Math.sign(d1 * -1);
  return (
    Math.abs(d1) < Math.abs(d2) ? d1 : d2
  );
}

export function lerp(min = 0, max = 0, steps = 0) {
  const positiveSteps = steps < 0 ? 0 : steps;
  const step = (max - min) / (positiveSteps + 1);
  const transRegion = Array(positiveSteps)
    .fill(undefined)
    .map((_, i) => min + step * (i + 1));
  return [min, ...transRegion, max];
}

export function loopLerp(min = 0, max = 0, steps = 0, edge = 0) {
  const positiveSteps = steps < 0 ? 0 : steps;
  const diff = minDiff(min, max, edge);
  const stepSize = diff / (positiveSteps + 1);
  const transRegion = Array(positiveSteps)
    .fill(undefined)
    .map((_, i) => {
      let step = (min + stepSize * (i + 1)) % edge;
      return step < 0 ? edge + step : step;
    });
  return [min, ...transRegion, max];
}

export function clamp(
  value, {
    min = null,
    max = null,
    circular = false
  } = {}
) {
  let v = value;
  v = isNumber(min) ? Math.max(v, min) : v;
  v = isNumber(max) ? Math.min(v, max) : v;

  if (circular && typeof min === 'number' && typeof max === 'number') {
    const diff = value % max;
    v = diff < 0 ? max + diff : diff;
  }

  return v;
};

export const clampHSLComponent = (value, component) => (
  clamp(value, {
    min: 0,
    max: component === 'h' ? 360 : 100,
    circular: component === 'h'
  })
);

export const defaultTo = (...values) => {
  for (let value of values) {
    if (value != null && !Number.isNaN(value)) return value;
  }
  return values[0];
};

/**
 * Constructs an unescaped string from a URLSearchParams object
 *
 * @param {Object} searchParams - the URLSearchParams object
 * @return {string}
 */
export function searchParamsToString(searchParams) {
  const entries = Array.from(searchParams.entries());
  return entries.map(p => `${p[0]}=${p[1]}`).join('&');
}

export function seriesFromTemplate(template = [], edge = null) {
  if (!Array.isArray(template))
    throw new Error(
      `Template for seriesFromTemplate() should be an array, but got ${typeof template}.`
    );

  if (template.length === 0) return [];

  if (
    typeof template[0] !== 'number' ||
    typeof template[template.length - 1] !== 'number'
  )
    throw new Error(
      'First and last item of the template of seriesFromTemplate() should be a number.'
    );

  const min = template[0];
  const maxIndex = template.slice(1).findIndex(n => typeof n === 'number') + 1;
  const max = template[maxIndex];
  let calculatedRegion = typeof edge === 'number'
    ? loopLerp(min, max, maxIndex - 1, edge).slice(1, -1)
    : lerp(min, max, maxIndex - 1).slice(1, -1);

  if (maxIndex !== template.length - 1) {
    const nextSlice = template.slice(maxIndex);
    calculatedRegion = [
      ...calculatedRegion,
      max,
      ...seriesFromTemplate(nextSlice, edge).slice(1, -1)
    ];
  }

  return [min, ...calculatedRegion, template[template.length - 1]];
}

export function hslSeriesFromTemplate({
  template,
  lightness = [],
  saturation = [],
  replicateHue = true,
  replicateSat = true,
  replicateLight = true,
  hplColorSpace = false,
  sampleColor
}) {
  let series = { h: [], s: [], l: [] };

  const componentTemplate = component =>
    ({ l: lightness, s: saturation }[component] || []);

  const shouldReplicateValue = component => (
    (component === 'h' && replicateHue)
    || (component === 's' && replicateSat)
    || (component === 'l' && replicateLight)
  );

  const isAnyHSLValueSet = (template, comp) => (
    Boolean(template.find(colorObj => typeof colorObj[comp] === 'number'))
  );

  const colorToHex = (h, s, l) => (
    hplColorSpace ? hpluvToHex([h, s, l]) : hsluvToHex([h, s, l])
  );

  const colorToLch = (h, s, l) => (
    hplColorSpace ? hpluvToLch([h, s, l]) : hsluvToLch([h, s, l])
  );

  for (let component of ['h', 's', 'l']) {
    let values;
    if (isAnyHSLValueSet(template, component)) {
      values = template.map(c => c[component]);
    } else {
      values = template.map((c, i) =>
        firstNumber([c[component], componentTemplate(component)[i]])
      );
    }

    // Ensure that first and last values are numbers, so we can calculate
    // the transition between them.

    // First item: copy closest hue value, or set value to 0
    if (typeof values[0] !== 'number') {
      values[0] = shouldReplicateValue(component)
        ? values.find(isNumber) || 0
        : 0;
    }

    // Last item: copy closest hue value, or simply set value to 0
    if (typeof values[values.length - 1] !== 'number') {
      values[values.length - 1] = shouldReplicateValue(component)
        ? [...values].reverse().find(isNumber) || 0
        : 0;
    }

    // Calculate the transition
    const edge = component === 'h' ? 360 : null;
    series[component] = seriesFromTemplate(values, edge);
  }

  const sampleLuminance = (
    sampleColor ? luminance(...hexToRGB(sampleColor)) : null
  );

  // Return with id appended
  return template.map((_, i) => {
    const hex = colorToHex(series.h[i], series.s[i], series.l[i]);
    const lum = luminance(...hexToRGB(hex));
    const contrast = (
      typeof sampleLuminance === 'number'
        ? contrastRatio(lum, sampleLuminance)
        : null
    );
    return {
      id: template[i].id,
      h: series.h[i],
      s: series.s[i],
      l: series.l[i],
      c: colorToLch(series.h[i], series.s[i], series.l[i])[1],
      lum,
      hex,
      contrast,
      sampleColor,
      disabledComps: template[i].disabledComps
    }
  });
}

export const disabledCompsFor = index =>
  ({ 0: ['h', 's'], 1: ['l'] }[index] || ['l']);

export const newField = (disabledComps = []) => ({
  id: shortid.generate(),
  h: null,
  s: null,
  l: null,
  disabledComps
});

export const newShadeFields = (count, disabledComps = []) =>
  Array(count)
    .fill(undefined)
    .map(() => newField(disabledComps));

export function resizeColorTemplate({
  template = [],
  ramps,
  shades,
  lsRamps = false
}) {
  const existingRamps = template.length;
  const existingShades = template[0] ? template[0].length : 0;

  if (ramps < 1 || shades < 1) return [];
  if (existingRamps === ramps && existingShades === shades)
    return [...template];

  let newTemplate = [...template];

  const rampDiff = ramps - existingRamps;
  const shadeDiff = shades - existingShades;

  // Adding new ramps to the end
  if (rampDiff > 0) {
    const newRamps = Array(rampDiff)
      .fill(undefined)
      .map((_, index) => {
        const disabledComps = lsRamps
          ? disabledCompsFor(existingRamps + index)
          : [];
        return newShadeFields(existingShades, disabledComps);
      });

    newTemplate = [...newTemplate, ...newRamps];

    // Removing ramps from the end
  } else if (rampDiff < 0) {
    newTemplate.splice(rampDiff);
  }

  // Adding new shades just before the last column
  if (shadeDiff > 0) {
    newTemplate = newTemplate.map((ramp, index) => {
      const disabledComps = lsRamps ? disabledCompsFor(index) : [];
      return [
        ...ramp.slice(0, -1),
        ...newShadeFields(shadeDiff, disabledComps),
        ...ramp.slice(-1)
      ];
    });

    // Removing shades from before the last column
  } else if (shadeDiff < 0 && shades > 1) {
    newTemplate = newTemplate.map(ramp => [
      ...ramp.slice(0, shades - 1),
      ...ramp.slice(-1)
    ]);

    // For the last two remaining, first column gets priority over the last
  } else if (shadeDiff < 0) {
    newTemplate = newTemplate.map(ramp => ramp.slice(0, shades));
  }

  return newTemplate;
}

export function luminance(r, g, b) {
  const rgb = [r, g, b].map(v => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : ((v + 0.055) / 1.055) ** 2.4;
  });
  return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2];
}

export function hexToRGB(hex) {
  return (
    hex
    .substring(1)
    .match(/.{2}/ig)
    .map(
      hexnum => Number.parseInt(hexnum, 16)
    )
  );
}

export function contrastRatio(l1, l2) {
  l1 += 0.05;
  l2 += 0.05;

  return l1 > l2 ? l1 / l2 : l2 / l1;
}
