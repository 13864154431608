import React from 'react';
import './ContentBlock.scss';

function ContentBlock(props) {
  return (
    <div className='c-ContentBlock'>{props.children}</div>
  );
}

export default ContentBlock;
