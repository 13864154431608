import React from "react";
import PropTypes from "prop-types";
import "./Input.scss";
import cx from "classnames";

function Input({circular, withScrubber, forwardedRef, transparent, ...props}) {
  return (
    <input
      {...props}
      ref={forwardedRef}
      className={cx(
        "c-Input", {
        "c-Input--WithScrubber": withScrubber,
        "c-Input--Transparent": transparent
      })}
    />
  );
}

Input.propTypes = {
  type: PropTypes.oneOf(["text", "password", "email", "number"]),
  withScrubber: PropTypes.bool,
  transparent: PropTypes.bool
};

Input.defaultProps = {
  type: "text"
};

export default Input;
