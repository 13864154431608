import React from "react";
import PropTypes from "prop-types";
import Swatch from "components/Swatch/Swatch";
import Button from "components/Button/Button";
import "./ColorRamp.scss";
import cx from "classnames";

function ColorRamp(props) {
  return (
    <tr className={
      cx("c-ColorRamp", {
        "c-ColorRamp--Standalone": props.standAlone,
        "c-ColorRamp--Gapless": !props.showGap
      })
    }>
      {props.showHeader &&
        <th
          className="c-ColorRamp__Head"
          style={{ backgroundColor: props.bgColor }}
        >
          {props.label &&
            <header className="c-ColorRamp__HeadHeader">
              <div className="c-ColorRamp__Label">{props.label}</div>
              {props.clickOnDelete &&
                <div className="c-ColorRamp__Delete">
                  <Button
                    label="Delete"
                    icon="close"
                    iconButton
                    onClick={props.clickOnDelete}
                  />
                </div>
              }
            </header>
          }
        </th>
      }
      {props.colors.map((color, i) => (
        <td className="c-ColorRamp__Item" key={color.id}>
          <Swatch
            {...color}
            template={props.template && props.template[i]}
            showValues={props.showValues}
            showWhiteBand={props.showWhiteBand}
            selectedId={props.selectedId}
            borderColor={props.swatchBorderColor}
            grayscaleMode={props.grayscaleMode}
            onClick={() => props.clickOnSwatch(color.id)}
            onHslChange={props.onHslChange}
            onHslClick={props.onHslClick}
          />
        </td>
      ))}
    </tr>
  );
};

ColorRamp.propTypes = {
  colors: PropTypes.array,
  template: PropTypes.array,
  showValues: PropTypes.bool,
  showGap: PropTypes.bool,
  showHeader: PropTypes.bool,
  showWhiteBand: PropTypes.bool,
  grayscaleMode: PropTypes.bool,
  clickOnDelete: PropTypes.func,
  clickOnSwatch: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.string
};

ColorRamp.defaultProps = {
  colors: [],
  showValues: true,
  showGap: true,
  showWhiteBand: false,
  showBorder: false,
  showHeader: false,
  grayscaleMode: false,
};

export default ColorRamp;
