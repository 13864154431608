import React from "react";
import PropTypes from "prop-types";
import CustomPropTypes from 'lib/custom-prop-types';
import nearestColor from 'nearest-color';
import namedColors from 'color-name-list';
import PanelManager from "components/PanelManager/PanelManager";
import Panel from "components/Panel/Panel";
import Modal from "components/Modal/Modal";
import Section from "components/Section/Section";
import ContentBlock from "components/ContentBlock/ContentBlock";
import ColorPalette from "components/ColorPalette/ColorPalette";
import ColorPicker from "components/ColorPicker/ColorPicker";
import Text from "components/Text/Text";
import {
  clampHSLComponent,
  resizeColorTemplate,
  hslSeriesFromTemplate,
  searchParamsToString
} from "lib/utils";
import {
  paletteToJSON,
  urlColorString
} from "lib/dataConverters";
import { colorHex6 } from "lib/stringPatterns";

const COLORS = namedColors.reduce((o, { name, hex }) => (
  Object.assign(o, { [name]: hex })
), {});

const getColorName = nearestColor.from(COLORS);

class App extends React.Component {
  static propTypes = {
    maintainLightness: PropTypes.bool,
    maintainSaturation: PropTypes.bool,
    sampleColor: CustomPropTypes.hexColor,
    sampleText: PropTypes.string,
    showValues: PropTypes.bool,
    showContrast: PropTypes.bool,
    showGap: PropTypes.bool,
    showWhiteBand: PropTypes.bool,
    maxRamps: PropTypes.number,
    maxShades: PropTypes.number
  };

  static defaultProps = {
    maintainLightness: true,
    maintainSaturation: true,
    sampleColor: '#ffffff',
    sampleText: '',
    showValues: false,
    showContrast: false,
    showGap: true,
    showWhiteBand: false,
    grayscaleMode: false,
    hplColorSpace: false,
    maxRamps: 16,
    maxShades: 16
  };

  state = {
    colorTemplate: [],
    selectedSwatchID: null,
    selectedColorName: null,
    maintainLightness: this.props.maintainLightness,
    maintainSaturation: this.props.maintainSaturation,
    sampleColor: this.props.sampleColor,
    sampleText: '',
    showValues: this.props.showValues,
    showContrast: this.props.showContrast,
    showGap: this.props.showGap,
    showWhiteBand: this.props.showWhiteBand,
    grayscaleMode: this.props.grayscaleMode,
    hplColorSpace: this.props.hplColorSpace,
    showExport: false
  };

  URLUpdateTimeout = null;  // for debouncing…

  // Initial settings
  componentDidMount() {
    this.load(this.props.colorData);
    this.loadSampleText(this.props.sampleText);
  }

  // Ramps and shades
  // ----------------

  // Number of ramps
  get ramps() {
    return this.state.colorTemplate.length
  }

  // Number of shades
  get shades() {
    return this.state.colorTemplate[0] ? this.state.colorTemplate[0].length : 0;
  }

  // Selected swatch ID
  get selectedID() {
    return this.state.selectedSwatchID;
  }

  // Color template
  get template() {
    return this.state.colorTemplate;
  }

  // Returns a given ramp
  ramp = index => this.state.colorTemplate[index];

  // Returns all shades at the given index
  shade = index => this.state.colorTemplate.map(ramp => ramp[index]);

  // Creates a new ramp
  addRamp = () => {
    this.updateColorTemplate(
      this.ramps + 1,
      this.shades
    );
  };

  // Adds a new shade
  addShade = () => {
    this.updateColorTemplate(
      this.ramps,
      this.shades + 1
    );
  }

  // Checks if a ramp contains any key values
  isRampTouched = (index) => (
    this.ramp(index).find(
      item => item.h !== null || item.s !== null || item.l !== null
    )
  );

  // Checks if a shade column contains any key values
  isShadeTouched = (index) => (
    this.shade(index).find(
      item => item.h !== null || item.s !== null || item.l !== null
    )
  );

  // Deletes a ramp
  deleteRamp = index => {
    const doDelete = () => {
      this.setState(state => ({
        colorTemplate: state.colorTemplate.filter((_, i) => i !== index)
      }),
        this.updateURLParams
      );
    }
    const confirmed = !this.isRampTouched(index) || window.confirm(`Are you sure you want to delete ramp ${index + 1}?`);
    if (confirmed) {
      if (this.isSelectionInRamp(index)) this.deselectSwatch(doDelete());
      else doDelete();
    }
  };

  // Deletes a shade column
  deleteShade = index => {
    const doDelete = () => {
      this.setState(state => ({
        colorTemplate: resizeColorTemplate({
          template: state.colorTemplate.map(ramp => ramp.filter((_, i) => i !== index)),
          ramps: this.ramps,
          shades: (this.shades - 1) || 1,
          lsRamps: true
        })
      }),
        this.updateURLParams
      );
    };

    const confirmed = !this.isShadeTouched(index) || window.confirm(`Are you sure you want to delete all shades at position ${index + 1}?`);
    if (confirmed) {
      if (this.isSelectionAtShadeCol(index)) this.deselectSwatch(doDelete());
      else doDelete();
    }
  }

  // Swatches
  // --------

  // Returns a swatch, with either template or computed values
  swatch = (id, template = [...this.state.colorTemplate]) => {
    const colorById = c => c.id === id;
    let swatch;
    for (let ramp of template) {
      swatch = ramp.find(colorById);
      if (swatch) break;
    }
    return swatch;
  }

  // Select a swatch
  selectSwatch = (id) => {
    this.setState({
      selectedSwatchID: id
    });
  }

  // Remove selection from swatch
  deselectSwatch = (callback) => {
    this.setState(
      { selectedSwatchID: null },
      () => callback && callback()
    );
  }

  isSelectionInRamp = index => (
    Boolean(
      this.selectedID &&
      this.ramp(index).find(swatch => swatch.id === this.selectedID)
    )
  );

  isSelectionAtShadeCol = index => (
    Boolean(
      this.selectedID &&
      this.shade(index).find(swatch => swatch.id === this.selectedID)
    )
  );

  // Update swatches
  updateSwatches(colors = [], callback) {
    let template = [...this.state.colorTemplate];

    for (let color of colors) {
      let swatch = this.swatch(color.id, template);

      ["h", "s", "l"].forEach(component => {
        if (Object.prototype.hasOwnProperty.call(color, component)) {
          if ([null, undefined].includes(color[component])) {
            swatch[component] = null;
          } else {
            const value = Number.parseFloat(color[component]);
            swatch[component] = Number.isNaN(value)
              ? 0
              : clampHSLComponent(value, component);
          }
        }
      });
    }

    this.setState(
      { colorTemplate: template },
      () => {
        this.updateURLParams();
        callback && callback();
      }
    );
  }

  // Templates and final colors
  // --------------------------

  // Returns the first ramp as lightness template
  lightnessTemplate = colorTemplate => (
    (colorTemplate[0] || []).map(color => color.l)
  );

  // Returns the second ramp as saturation template
  saturationTemplate = colorTemplate => {
    const ramp = colorTemplate[1] || [];
    return ramp.map(color => color.s);
  };

  // Assembles the final color palette from the templates
  colors = () => {
    const lightnessTemplate =
      this.state.maintainLightness &&
      this.lightnessTemplate(this.state.colorTemplate);

    const saturationTemplate =
      this.state.maintainSaturation &&
      this.saturationTemplate(this.state.colorTemplate);

    return this.state.colorTemplate.map((rampTemplate, i) =>
      hslSeriesFromTemplate({
        template: rampTemplate,
        lightness: lightnessTemplate,
        saturation: i > 0 && saturationTemplate,
        hplColorSpace: this.state.hplColorSpace,
        sampleColor: this.state.showContrast && this.state.sampleColor
      })
    );
  };

  // Resize the color template
  updateColorTemplate = (ramps, shades, callback) => {
    if (ramps > this.props.maxRamps || shades > this.props.maxShades) {
      alert(`Palette size is limited to ${this.props.maxRamps} ramps and ${this.props.maxShades} shades.`);
      return;
    }
    this.setState(() => ({
      colorTemplate: resizeColorTemplate({
        template: this.state.colorTemplate,
        ramps,
        shades,
        lsRamps: true
      })
    }),
      () => {
        this.updateURLParams();
        if (typeof callback === "function") callback();
      }
    );
  };

  // Color operations
  // ----------------

  // Handle HSL changes for a swatch
  onHslChange = (id, fieldName, value) => {
    this.updateSwatches(
      [{ id, [fieldName]: value }]
    );
  };

  // Handle HSL key button click
  onHslClick = (id, fieldName, colors) => {
    const swatch = this.swatch(id);
    const value = swatch[fieldName];
    let newValue = value === null
      ? this.swatch(id, colors)[fieldName]
      : null;
    this.updateSwatches(
      [{ id, [fieldName]: newValue }],
    );
  };

  // Sample text
  // -----------

  // Load sample text from path
  loadSampleText = samplePath => {
    fetch(samplePath)
      .then(response => response.text())
      .then(text => this.setState({ sampleText: text }))
      .catch(error => {
        console.error(error);
      });
  };

  // Set foreground color of sample text
  sampleColorInputChangeHandler = event => {
    const hexInput = event.currentTarget.value;
    if (colorHex6.test(hexInput)) {
      this.setState({sampleColor: hexInput});
    }
  };

  // Set sample text
  sampleTextChangeHandler = event => {
    this.setState({sampleText: event.currentTarget.value});
  };

  // Loading and saving
  // ------------------

  // Load palette
  load({ramps = 2, shades = 3, colors = {}, hplColorSpace = false} = {}) {
    this.setState(() => ({ hplColorSpace }), () => {
      this.updateColorTemplate(ramps, shades, () => {
        let swatchSettings = [];
        for (let rampIdx of Object.keys(colors)) {
          for (let shadeIdx of Object.keys(colors[rampIdx])) {
            if (
              this.template[Number(rampIdx)] &&
              this.template[Number(rampIdx)][Number(shadeIdx)]
            ) {
              swatchSettings.push({
                id: this.template[Number(rampIdx)][Number(shadeIdx)].id,
                ...colors[rampIdx][shadeIdx]
              });
            }
          }
        }
        this.updateSwatches(
          swatchSettings
        );
      });
    });
  }

  updateURLParams() {
    window.clearTimeout(this.URLUpdateTimeout);
    this.URLUpdateTimeout = window.setTimeout(() => {
      let changes = {};
      changes.ramps = this.ramps;
      changes.shades = this.shades;
      changes.space = this.state.hplColorSpace ? 'hpluv' : 'hsluv';
      changes.colors = urlColorString(paletteToJSON(this.state.colorTemplate));

      let url = new URL(window.location);
      let searchParams = new URLSearchParams(url.search);

      for (let key of Object.keys(changes)) {
        searchParams.set(key, changes[key]);
      }

      url.search = searchParamsToString(searchParams);
      window.history.replaceState(null, null, url);
    }, 500);
  }

  // Exporting
  // ---------

  sassCode = () => (
    this.colors()
      .map(ramp => ramp.map(hsl => hsl.hex))
      .join("\n")
  );

  openExport = () => {
    this.setState({showExport: true});
  };

  closeExport = () => {
    this.setState({showExport: false});
  };


  // View operations
  // ---------------

  toggleValues = () => {
    this.setState(state => ({ showValues: !state.showValues }));
  };

  toggleContrast = () => {
    this.setState(state => ({ showContrast: !state.showContrast }));
  };

  toggleGap = () => {
    this.setState(state => ({ showGap: !state.showGap }));
  };

  toggleWhiteBand = () => {
    this.setState(state => ({ showWhiteBand: !state.showWhiteBand }));
  };

  toggleGrayscale = () => {
    this.setState(state => ({ grayscaleMode: !state.grayscaleMode }));
  };


  // Settings
  // --------

  toggleHPL = () => {
    this.setState(
      state => ({ hplColorSpace: !state.hplColorSpace }),
      this.updateURLParams
    );
  };


  // Styling touches
  // ---------------

  swatchBorderColor = lightness => (lightness < .7 ? "#131313" : null);


  // Render
  // ------

  render() {

    const colors = this.colors();
    const bgColor = "#000000";

    return (
      <>
        <PanelManager>
          <Panel
            header
            footer
            title="🐭 Mad Science"
            objectTitle="Colors"
            color={bgColor}
            viewActions={[
              {
                label: "Values",
                icon: "visibility_off",
                iconOn: "visibility",
                on: this.state.showValues,
                onClick: this.toggleValues
              }, {
                label: "Contrast",
                icon: "panorama_fish_eye",
                iconOn: "loupe",
                on: this.state.showContrast,
                onClick: this.toggleContrast
              }, {
                label: "Gap",
                icon: "view_stream",
                iconOn: "view_module",
                on: this.state.showGap,
                onClick: this.toggleGap
              }, {
                label: "Band",
                icon: "invert_colors_off",
                iconOn: "invert_colors",
                on: this.state.showWhiteBand,
                onClick: this.toggleWhiteBand
              }, {
                label: "Color",
                labelOn: "Grayscale",
                icon: "color_lens",
                iconOn: "brightness_5",
                on: this.state.grayscaleMode,
                onClick: this.toggleGrayscale
              }, {
                label: "HSLuv",
                labelOn: "HPLuv",
                icon: "brightness_1",
                iconOn: "brightness_2",
                on: this.state.hplColorSpace,
                onClick: this.toggleHPL
              }
            ]}
            actions={[
              {
                label: "Add ramp",
                icon: "add",
                onClick: this.addRamp
              }, {
                label: "Add shade",
                icon: "add",
                onClick: this.addShade
              }, {
                label: "Export palette",
                icon: "get_app",
                onClick: this.openExport
              }
            ]}
          >
            <Section style={{ paddingLeft: 0, paddingTop: 0 }}>
              <ColorPalette
                template={this.state.colorTemplate}
                bgColor={bgColor}
                colors={colors}
                selectedId={this.selectedID}
                swatchBorderColor={this.swatchBorderColor}
                showValues={this.state.showValues}
                showGap={this.state.showGap}
                showWhiteBand={this.state.showWhiteBand}
                grayscaleMode={this.state.grayscaleMode}
                clickOnSwatch={id => this.selectSwatch(id, colors)}
                clickOnRampDelete={this.deleteRamp}
                clickOnShadeDelete={this.deleteShade}
                onHslChange={this.onHslChange}
                onHslClick={(swatchId, field) => this.onHslClick(swatchId, field, colors)}
                showHeaders
                contrastBased
                stickyHeaders
              />
            </Section>
          </Panel>
          {this.selectedID ? PickerPanel.call(this, colors) : ''}
        </PanelManager>
        {this.state.showExport && ExportPanel.call(this)}
      </>
    );
  }
}

function PickerPanel(colors) {
  const selectedSwatchFinal = this.swatch(this.selectedID, colors);
  const selectedHex = selectedSwatchFinal ? selectedSwatchFinal.hex : undefined;
  const colorName = (
    selectedHex
      ? getColorName(selectedHex.substring(1)).name
      : ''
  );

  return (
    <Panel
      shrunk
      header
      objectTitle={`${selectedHex} (${colorName})`}
      closeAction={() => this.deselectSwatch()}
    >
      <ColorPicker
        hex={selectedSwatchFinal.hex}
        h={selectedSwatchFinal.h}
        s={selectedSwatchFinal.s}
        l={selectedSwatchFinal.l}
        template={this.swatch(this.selectedID)}
        sampleColor={this.state.sampleColor}
        sampleText={this.state.sampleText}
        hplColorSpace={this.state.hplColorSpace}
        onHslChange={(field, value) => this.onHslChange(this.selectedID, field, value)}
        onHslClick={field => this.onHslClick(this.selectedID, field, colors)}
        onSampleColorInputChange={this.sampleColorInputChangeHandler}
        onSampleTextChange={this.sampleTextChangeHandler}
      />
    </Panel>
  );
}

function ExportPanel() {
  return (
    <Modal
      title="Export colors"
      closeAction={this.closeExport}
    >
      <Section>
        <ContentBlock>
          <Text>
            <pre>
              <code>
                {this.sassCode()}
              </code>
            </pre>
          </Text>
        </ContentBlock>
      </Section>
    </Modal>
  );
}

export default App;
