import React from "react";
import PropTypes from "prop-types";
import "./Field.scss";
import cx from "classnames";

function Field(props) {
  return (
    <div className={cx(
      "c-Field", {
        "c-Field--Horizontal": props.horizontal,
        "c-Field--Highlighted": props.highlighted
      }
    )}>
      <label className="c-Field__LabelContainer">
        <span className="c-Field__Label">{props.label}</span>
        <div className="c-Field__Input">
          {props.children}
        </div>
      </label>
      { props.hint &&
        <p className="c-Field__Hint">{props.hint}</p>
      }
    </div>
  );
}

Field.propTypes = {
  label: PropTypes.string.isRequired,
  hint: PropTypes.string,
  horizontal: PropTypes.bool,
  highlighted: PropTypes.bool
};

export default Field;
