import React from "react";
import PropTypes from "prop-types";
import CustomPropTypes from 'lib/custom-prop-types';
import Section from "components/Section/Section";
import ColorSample from "components/ColorSample/ColorSample";
import Field from "components/Field/Field";
import Group from "components/Group/Group";
import Input from "components/Input/Input";
import Button from "components/Button/Button";
import TextArea from "components/TextArea/TextArea";
import withScrubber from "behaviour/scrubber/scrubber";
import { formatNumber, objectFromArray } from "lib/utils";
import { hexToHsluv, hexToHpluv } from "hsluv";
import { colorHex6 } from "lib/stringPatterns";

const InputWithScrubber = withScrubber(Input);

class ColorPicker extends React.Component {

  static propTypes = {
    hex: CustomPropTypes.hexColor,
    sampleColor: CustomPropTypes.hexColor,
    sampleText: PropTypes.string,
    h: PropTypes.number,
    s: PropTypes.number,
    l: PropTypes.number,
    hplColorSpace: PropTypes.bool,
    onHslChange: PropTypes.func,
    onSampleColorInputChange: PropTypes.func,
    onSampleTextChange: PropTypes.func
  };

  state = {
    targetHex: undefined
  };

  onTargetHexChange = e => {
    const hexInput = e.currentTarget.value;
    this.setState({
      targetHex: colorHex6.test(hexInput) ? hexInput : undefined
    });
  }

  targetHSL = () => {
    if (this.state.targetHex) {
      let values = (
        this.props.hplColorSpace
          ? hexToHpluv(this.state.targetHex)
          : hexToHsluv(this.state.targetHex)
      );
      values = values.map(v => formatNumber(v, 1, true));
      return objectFromArray(values, 'hsl');
    }
    return undefined;
  };

  render() {
    const targetHSLValues = this.targetHSL();

    return (
      <>
        <ColorSample
          color={this.props.hex}
          textColor={this.props.sampleColor}
          text={this.props.sampleText}
        />
        <Section separated>
          <Group
            items={ ['h', 's', 'l'].map(f => HslField(f, targetHSLValues, this.props)) }
          />
          <Field label="Target hex">
            <Input name="targetHex" onChange={this.onTargetHexChange} />
          </Field>
        </Section>
        <Section separated>
          <Field label="Sample color">
            <Input
              defaultValue={this.props.sampleColor}
              name="sampleColor"
              onChange={this.props.onSampleColorInputChange}
            />
          </Field>
          <Field label="Sample text">
            <TextArea
              value={this.props.sampleText}
              name="sampleText"
              rows="5"
              onChange={this.props.onSampleTextChange}
            />
          </Field>
        </Section>
      </>
    );
  }
};

function HslField(field, targetHSLValues, props) {

  const isKey = field => props.template && props.template[field] !== null;

  const isDisabled = field => (
    props.template && props.template.disabledComps.includes(field)
  );

  return (
    <Field
      label={{h: 'Hue', s: 'Saturation', l: 'Lightness'}[field]}
      highlighted={isKey(field)}
      hint={targetHSLValues ? targetHSLValues[field] : null}
    >
      <InputWithScrubber
        value={formatNumber(props[field], 1, true)}
        name={field}
        type="text"
        min={0}
        max={field === 'h' ? 360 : 100}
        size={5}
        circular={field === 'h'}
        disabled={!isKey(field)}
        onChange={value => props.onHslChange(field, value)}
      />
      { !isDisabled(field) &&
        <Button
          label="Make key"
          labelOn="Remove key"
          icon="control_point"
          iconOn="cancel"
          iconButton
          on={isKey(field)}
          onClick={() => props.onHslClick && props.onHslClick(field)}
        />
      }
    </Field>
  );
}

export default ColorPicker;
