import React from 'react';
import ReactDOM from 'react-dom';
import "./styles/defaults/defaults.scss";
import "./styles/literals/literals.scss";
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'focus-visible/dist/focus-visible.js';
import { paletteURLParamsToJSON } from 'lib/dataConverters';
import introPath from 'intro.md';

const colorData = {
  'ramps': 5,
  'shades': 5,
  'colors': {
    '0': { '0': { 'l': 10 },
           '4': { 'l': 75 }},
    '1': { '0': { 'h': 12, 's': 52 }},
    '2': { '0': { 'h': 249 }},
    '3': { '0': { 'h': 288 }},
    '4': { '0': { 'h': 105 }}
  }
};

const urlColorData = paletteURLParamsToJSON(window.location.search);

const dataToLoad = (urlColorData.ramps && urlColorData.shades) ? urlColorData : colorData;

ReactDOM.render(
  <App
    colorData={dataToLoad}
    sampleText={introPath}
    sampleColor={"#ffffff"}
    maintainLightness
    maintainSaturation
    showValues
    showGap
    maxRamps={20}
    maxShades={20}
  />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
