import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Button from "components/Button/Button";
import "./Panel.scss";

const Panel = props => (
  <div className={cx(
    "c-Panel", {
    "c-Panel--Shrunk": props.shrunk && !props.floated,
    "c-Panel--Floated": props.floated
    })}
  >
    {props.header && (
      <header className="c-Panel__Header">
        {(props.title || props.objectTitle) && (
          <h1 className="c-Panel__Title">
            {props.title && props.title + (props.objectTitle ? ": " : "")}
            {props.objectTitle && <strong>{props.objectTitle}</strong>}
          </h1>
        )}
        <menu className="c-Panel__Toolbar">
          {props.viewActions && props.viewActions.map(action => (
            <li key={action.label}><Button {...action} /></li>
          ))}
          {props.closeAction && (
            <li>
              <Button
                label="Close panel"
                icon="close"
                iconButton
                onClick={props.closeAction}
              />
            </li>
          )}
        </menu>
      </header>
    )}
    <div className="c-Panel__Content" style={{ backgroundColor: props.color }}>
      {props.children}
    </div>
    {props.footer && (
      <footer className="c-Panel__Footer">
        {props.status && <p className="c-Panel__Status">{props.status}</p>}
        <menu className="c-Panel__Toolbar">
          {props.actions && props.actions.map(action => (
            <li key={action.label}><Button {...action} /></li>
          ))}
        </menu>
      </footer>
    )}
  </div>
);

Panel.propTypes = {
  header: PropTypes.bool,
  footer: PropTypes.bool,
  shrunk: PropTypes.bool,
  title: PropTypes.string,
  objectTitle: PropTypes.string,
  status: PropTypes.string,
  color: PropTypes.string,
  closeAction: PropTypes.func,
  viewActions: PropTypes.array,
  actions: PropTypes.array
};

export default Panel;
