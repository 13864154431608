import React from 'react';
import './PanelManager.scss';

function PanelManager(props) {
  return (
    <div className='c-PanelManager'>
      {props.children}
    </div>
  );
}

export default PanelManager;
