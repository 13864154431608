import React from 'react';
import PropTypes from 'prop-types';
import ColorRamp from 'components/ColorRamp/ColorRamp';
import Button from 'components/Button/Button';
import './ColorPalette.scss';
import cx from "classnames";

function ColorPalette(props) {
  const rampLabel = index => (
    props.contrastBased &&
    index < 2 ? index === 0
      ? "Lightness"
      : "Saturation"
      : `Color ${index}`
  );

  return (
    <table className={cx(
      'c-ColorPalette', {
      'c-ColorPalette--Gapless': !props.showGap,
      'c-ColorPalette--StickyHeaders': props.stickyHeaders
    })}>
      {props.showHeaders &&
        <thead className="c-ColorPalette__Header">
          <tr>
            <th
              className="c-ColorPalette__RampHeader"
              style={{ backgroundColor: props.bgColor }}
            ></th>
            {props.colors[0] && props.colors[0].map((shade, i) =>
              <th
                className="c-ColorPalette__ShadeHeader"
                style={{ backgroundColor: props.bgColor }}
                key={`shadeheader-${i + 1}`}
              >
                {i + 1}
                {props.colors[0].length > 1 &&
                  <Button
                    label="Delete"
                    icon="close"
                    iconButton
                    onClick={() => props.clickOnShadeDelete(i)}
                  />
                }
              </th>
            )}
          </tr>
        </thead>
      }
      <tbody>
        {props.colors.map((ramp, i) =>

          <ColorRamp
            label={rampLabel(i) || props.label}
            bgColor={props.bgColor}
            colors={ramp}
            template={props.template[i]}
            key={`ramp-${i}`}
            selectedId={props.selectedId}
            showValues={props.showValues}
            showGap={props.showGap}
            showWhiteBand={props.showWhiteBand}
            showHeader={props.showHeaders}
            swatchBorderColor={props.swatchBorderColor}
            grayscaleMode={props.grayscaleMode}
            clickOnSwatch={props.clickOnSwatch}
            clickOnDelete={i > 1 ? (() => props.clickOnRampDelete(i)) : null}
            onHslChange={props.onHslChange}
            onHslClick={props.onHslClick}
          />
        )}
      </tbody>
    </table>
  );
}

ColorPalette.propTypes = {
  colors: PropTypes.array,
  template: PropTypes.array,
  selectedId: PropTypes.string,
  showValues: PropTypes.bool,
  showGap: PropTypes.bool,
  showWhiteBand: PropTypes.bool,
  showHeaders: PropTypes.bool,
  clickOnSwatch: PropTypes.func,
  clickOnRampDelete: PropTypes.func,
  clickOnShadeDelete: PropTypes.func,
  contrastBased: PropTypes.bool,
  stickyHeaders: PropTypes.bool,
  grayscaleMode: PropTypes.bool,
};

ColorPalette.defaultProps = {
  colors: [],
  template: [],
  stickyHeaders: true,
  showWhiteBand: false,
  grayscaleMode: false
};

export default ColorPalette;
