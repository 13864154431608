import React from 'react';
import { Remarkable } from 'remarkable';
import './Text.scss';
import cx from "classnames";

var md = new Remarkable('full', {
  typographer: true,
  linkify: true,
  super: true
});

function Text(props) {
  const HTMLTag = props.article ? 'article' : 'div';
  const classes = cx(
    "c-Text", {
    "c-Text--HangingBullets": props.hangingBullets,
    "c-Text--Mono": props.mono
  });

  if (props.markdown) {
    return (
      <HTMLTag
        className={classes}
        dangerouslySetInnerHTML={{ __html: md.render(props.children) }}
      />
    );
  } else {
    return (
      <HTMLTag className={classes}>
        {props.children}
      </HTMLTag>
    );
  }
}

Text.defaultProps = {
  hangingBullets: false
};

export default Text;
