// @ts-check

/**
 * Converts a colour colorTemplate to JSON
 *
 * @param {Array} colorTemplate - The colour colorTemplate
 * @return {Object}
 */
export function paletteToJSON(colorTemplate) {
  const ramps = colorTemplate.length;
  const shades = colorTemplate[0] ? colorTemplate[0].length : 0;

  let result = {};

  for (let rampIdx = 0; rampIdx < ramps; rampIdx++) {
    for (let shadeIdx = 0; shadeIdx < shades; shadeIdx++) {
      const swatch = colorTemplate[rampIdx][shadeIdx];
      let hsl = {};
      for (let comp of ['h', 's', 'l']) {
        if (swatch[comp] != null) hsl[comp] = swatch[comp];
      }
      if (Object.keys(hsl).length > 0) {
        if (!Object.prototype.hasOwnProperty.call(result, String(rampIdx))) {
          result[String(rampIdx)] = {};
        }
        result[String(rampIdx)][String(shadeIdx)] = hsl;
      }
    }
  }
  return result;
}

/**
 * Converts JSON-formatted palette data to URL parameters
 *
 * @param {Object} paletteData - The palette data in JSON
 * @return {string}
 */
export function paletteJSONToURLParams(paletteData) {
  return (
    Object.keys(paletteData).map(k => {
      let value = paletteData[k];
      // eslint-disable-next-line
      if (k == 'colors') {
        value = urlColorString(paletteData[k]);
      }
      return `${k}=${value}`;
    }).join('&')
  );
}

/**
 * Converts color data to URL paramter string
 *
 * @param {Object} colors - The color data in JSON format
 * @return {string}
 */
export function urlColorString(colors) {
  let result = [];
  for (let rampIdx of Object.keys(colors)) {
    for (let shadeIdx of Object.keys(colors[rampIdx])) {
      const position = `${rampIdx},${shadeIdx}`;
      const values = urlHSLString(colors[rampIdx][shadeIdx]);
      result.push(`${position}:${values}`);
    }
  }
  return result.join(";")
}

/**
 * Converts HSL data to URL parameter string
 *
 * @param {Object} hslValues - The HSL values in JSON format
 * @return {string}
 */
function urlHSLString(hslValues) {
  return (
    Object.keys(hslValues).map(
      comp => `${comp}${hslValues[comp]}`
    ).join(',')
  )
}

/**
 * Converts a URL string of palette data to JSON
 *
 * @param {string} urlParamsStr - The palette data, encoded as URL parameters
 * @return {Object}
 */
export function paletteURLParamsToJSON(urlParamsStr = '') {
  const searchParams = new URLSearchParams(urlParamsStr);
  let output = {};

  if (searchParams.has('ramps')) output.ramps = Number(searchParams.get('ramps'));
  if (searchParams.has('shades')) output.shades = Number(searchParams.get('shades'));
  if (searchParams.has('space')) {
    output.hplColorSpace = searchParams.get('space') === 'hpluv' ? true : false;
  }
  if (searchParams.has('colors')) {
    output.colors = {};
    const colors = searchParams.get('colors').split(';');
    for (let color of colors) {
      const [indexes, values] = color.split(':');
      if (!indexes || !values) break;
      const [rampIdx, shadeIdx] = indexes.split(',');
      if (!rampIdx || !shadeIdx) break;
      const comps = values.split(',');

      if (!Object.prototype.hasOwnProperty.call(output.colors, rampIdx)) {
        output.colors[rampIdx] = {};
      }
      output.colors[rampIdx][shadeIdx] = {};
      for (let compStr of comps) {
        output.colors[rampIdx][shadeIdx][compStr[0]] = Number(compStr.substring(1));
      }
    }
  }

  return output;
}
