import React from "react";
import PropTypes from "prop-types";
import customPropTypes from "lib/custom-prop-types";
import cx from "classnames";
import "./Swatch.scss";
import Field from "components/Field/Field";
import Input from "components/Input/Input";
import Button from "components/Button/Button";
import withScrubber from "behaviour/scrubber/scrubber";
import { formatNumber } from "lib/utils";

const InputWithScrubber = withScrubber(Input);

const Swatch = props => {
  const borderColor =
    props.borderColor && typeof props.borderColor === "function"
      ? props.borderColor(props.l)
      : props.borderColor;

  const isKey = field => props.template && props.template[field] !== null;

  const hslFields = (hiddenHSLComponents = []) => (
    ['h', 's', 'l'].filter(c => !hiddenHSLComponents.includes(c))
  );

  const SwatchValueField = field => (
    typeof props.h === "number" && (
      <Field label={field} horizontal highlighted={isKey(field)}>
        <InputWithScrubber
          disabled={!isKey(field)}
          value={formatNumber(props[field], 1, true)}
          type="text"
          min={0}
          max={field === "h" ? 360 : 100}
          circular={field === "h"}
          onChange={value => props.onHslChange(props.id, field, value)}
        />
        <Button
          annexed
          label="Make key"
          labelOn="Remove key"
          iconButton
          icon="control_point"
          iconOn="cancel"
          on={isKey(field)}
          onClick={() => props.onHslClick(props.id, field)}
        />
      </Field>
    )
  );

  return (
    <div
      className={cx(
        "c-Swatch",
        { "c-Swatch--Interactive": !!props.onClick,
          "c-Swatch--Selected":
            props.selectedId && props.selectedId === props.id,
          "c-Swatch--FixWidth": props.fixWidth,
          "c-Swatch--WithWhiteBand": props.showWhiteBand,
          "c-Swatch--Grayscale": props.grayscaleMode
        }
      )}
    >
      <div
        className="c-Swatch__Color"
        style={{
          backgroundColor: props.hex,
          borderColor: borderColor,
          color: props.sampleColor
        }}
        tabIndex={props.onClick && "0"}
        onFocus={props.onClick}
      >
        {props.contrast && formatNumber( props.contrast, 1 )}
      </div>
      {props.showValues && (
        <>
          <ul className="c-Swatch__Values">
            <li className="c-Swatch__Value">
              <Field label="#" horizontal>
                <Input value={props.hex.substring(1)} disabled transparent />
              </Field>
            </li>
            { props.disabledComps.includes('l') &&
              <li className="c-Swatch__Value">
                <Field label="c" horizontal>
                  <Input value={formatNumber(props.c, 1, true)} disabled transparent />
                </Field>
              </li>
            }
            { hslFields(props.disabledComps).map(field => (
              <li
                className="c-Swatch__Value"
                key={field}
              >
                { SwatchValueField(field) }
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

Swatch.propTypes = {
  hex: customPropTypes.hexColor,
  h: PropTypes.number,
  s: PropTypes.number,
  l: PropTypes.number,
  c: PropTypes.number,
  lum: PropTypes.number,
  template: PropTypes.object,
  showValues: PropTypes.bool,
  showWhiteBand: PropTypes.bool,
  fixWidth: PropTypes.bool,
  borderColor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  grayscaleMode: PropTypes.bool,
  id: PropTypes.string,
  disabledComps: PropTypes.array,
  selectedId: PropTypes.string
};

Swatch.defaultProps = {
  showValues: true,
  showWhiteBand: false,
  grayscaleMode: false,
  fixWidth: false,
  disabledComps: []
};

export default Swatch;
