import React from 'react';
import PropTypes from 'prop-types';
import CustomPropTypes from 'lib/custom-prop-types';
import Text from "components/Text/Text";
import './ColorSample.scss';

function ColorSample(props) {
  const style = {
    backgroundColor: props.color,
    color: props.textColor,
    borderColor: props.textColor,
    fontSize: props.fontSize,
    fontWeight: props.fontWeight
  };

  return (
    <div className="c-ColorSample" style={style} >
      <div className="c-ColorSample__Content">
        <Text markdown mono>{props.text}</Text>
      </div>
    </div>
  );
}

ColorSample.propTypes = {
  color: CustomPropTypes.hexColor,
  textColor: CustomPropTypes.hexColor,
  text: PropTypes.string,
  fontWeight: PropTypes.string
};

ColorSample.defaultProps = {
  fontWeight: "normal"
}

export default ColorSample;
