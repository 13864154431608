import React from 'react';
import './Group.scss';

function Group(props) {
  return (
    <div className='c-Group'>
      {props.items.map((item, i) => <div className='c-Group__Item' key={`gi-${i}`}>{item}</div>)}
    </div>
  );
}

export default Group;
