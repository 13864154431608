import React from 'react';
import cx from "classnames";
import './Section.scss';

function Section(props) {
  const classes = cx(
    props.className,
    "c-Section", {
    "c-Section--Separated": props.separated
  });

  return (
    <section className={classes} style={props.style}>{props.children}</section>
  );
}

export default Section;
