import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Icon from "../Icon/Icon";
import "./Button.scss";
import CustomPropTypes from "lib/custom-prop-types";

const Button = ({ label, icon, primary, flipped, annexed, iconButton, on, iconOn, labelOn, ...standardProps }) => {
  const classes = cx("c-Button", {
    "c-Button--Flipped": flipped && icon,
    "c-Button--Primary": primary,
    "c-Button--Annexed": annexed,
    "c-Button--On": on === true,
    "c-Button--Off": on === false,
  });

  const activeLabel = () => (on && labelOn) || label;
  const activeIcon = () => (on && iconOn) || icon;

  return (
    <button className={classes} title={iconButton && activeLabel()} {...standardProps}>
      {icon && (
        <span className="c-Button__Icon">
          <Icon name={activeIcon()} />
        </span>
      )}
      {label && !(iconButton && icon) && (
        <span className="c-Button__Label">{activeLabel()}</span>
      )}
    </button>
  );
};

Button.propTypes = {
  icon: PropTypes.oneOf(Icon.names),
  iconOn: PropTypes.oneOf(Icon.names),
  flipped: PropTypes.bool,
  primary: PropTypes.bool,
  annexed: PropTypes.bool,
  iconButton: PropTypes.bool,
  on: PropTypes.bool,
  label: CustomPropTypes.label({max: 30, required: true}),
  labelOn: CustomPropTypes.label({max: 30}),
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  onClick: PropTypes.func,
};

Button.defaultProps = {
  icon: null,
  label: null,
  primary: false,
  flipped: false,
  type: "button"
};

export default Button;
